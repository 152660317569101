import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class Line extends Component {
  getOption = () => {
    const type = this.props.type
    const data = this.props.data
    return {
      legend: {},
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: "category",
        data: data?.periodos ?? [],
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: data?.instrumentos.map(e => { return {name: e.nombre, data: e.rendimientos.map((i) => type === '%' ? Number(i*100).toFixed(2) : i ), smooth: true, type: "line"}}),
      color: ['#2C78C2', '#CCC'],
      textStyle: {
        color: ["#74788d"],
      },
    };
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Line
