import React from "react";
import { Container } from "reactstrap";
import TabSurvey from "./Survey";

const PublicSurvey = () => {
  return (
    <React.Fragment>
      <div className="page-content pt-4">
        <div className="container mx-auto">
          <div className="col-12">
            <TabSurvey />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublicSurvey;
